import { cncMiniLazyload } from '@default/scripts/modules/lazy-load/cncMiniLazyload';
import { bleskHeader } from '@default/scripts/modules/blesk-header';

// import { mobileMenuWithBreakingNews } from '@default/scripts/modules/article/mobile-menu-with-breaking-news';

/** Spracovava lazyloading obrazku */
cncMiniLazyload();

/**
 * Init scriptu pro hlavicku Blesku:
 *    - otvirani velke navigace
 *    - prepnuti do sticky stavu
 *    - prizpusobeni poctu polozek u sticky menu
 *    - posouvani secondary menu (pokud existuje) do sticky headeru
 */
bleskHeader();

/**
 * Modul loadMore inituje a nastavuje komponentu LoadMore, ktera zajistuje asynchronni nacteni obsahu
 *
 * Dynamicky import modulu, pokud existuje element, na ktery se to ma aplikovat
 */
const dynamicLoadMore = async () => {
    /** @type {NodeListOf<HTMLElement>} */
    const loadMoreElements = document.querySelectorAll('[data-load-more]');

    if (loadMoreElements.length) {
        const { loadMore } = await import(
            /* webpackChunkName: 'chunk-load-more' */
            './modules/load-more'
        );

        loadMore(loadMoreElements);
    }
};

/**
 * Modul writerList zajistuje animovane otevirani popupu se seznamem autoru po najeti mysi
 *
 * Dynamicky import modulu, pokud existuje element, na ktery se to ma aplikovat
 */
const dynamicWriterList = async () => {
    /** @type {HTMLElement} */
    const writersElement = document.querySelector('.writer-list');

    if (writersElement) {
        const { writerList } = await import(
            /* webpackChunkName: 'chunk-writer-list' */
            './modules/writer-list'
        );

        writerList(writersElement);
    }
};

/**
 * Modul electionBanner zajistuje inicializaci a ovladani volebnich banneru
 *
 * Dynamicky import modulu, pokud existuje element, na ktery se ma aplikovat
 */
const dynamicElectionBannerList = async () => {
    /** @type {NodeListOf<HTMLElement>} */
    const electionBannerElements = document.querySelectorAll('div[id^=electionsBanner]');

    if (electionBannerElements.length > 0) {
        const { configureElectionBanner } = await import(
            /* webpackChunkName: 'chunk-election-banner-list' */
            '@default/scripts/modules/elections/electionBanner'
        );

        electionBannerElements.forEach((banner) => {
            configureElectionBanner(window.bannersProps[banner.id]);
        });
    }
};

/**
 * Modul electionSearch zajistuje inicializaci a ovladani volebniho vyhledavani
 *
 * Dynamicky import modulu, pokud existuje element, na ktery se ma aplikovat
 */
const dynamicElectionSearchList = async () => {
    /** @type {NodeListOf<HTMLElement>} */
    const electionSearchElements = document.querySelectorAll('.city-search');

    if (electionSearchElements.length > 0) {
        const { configureElectionSearch } = await import(
            /* webpackChunkName: 'chunk-election-search-list' */
            '@default/scripts/modules/elections/electionSearch'
        );

        electionSearchElements.forEach((searchItem) => {
            configureElectionSearch(searchItem);
        });
    }
};

/**
 * IDVERT
 */
const dynamicIdvertCompensation = async () => {
    if (document.querySelector('.idvert-wrapper')) {
        const { idvertCompensation } = await import(
            /* webpackChunkName: 'chunk-idvert-compensation' */
            './modules/idvert-compensation'
        );

        idvertCompensation();
    }
};

/**
 * RECOMBEE
 *
 * @description Načítání doporučených článků z Recombee do HTML elementu.
 * Používá se v detailu článku.
 */
const dynamicRecombeeArticlesInsert = async () => {
    const recombeeWrapper = document.getElementById('relatedProducts');

    if (recombeeWrapper) {
        const { recombeeArticles } = await import(
            /* webpackChunkName: 'chunk-recombee-articles-insert' */
            './modules/recombee-articles'
        );

        recombeeArticles(recombeeWrapper);
    }
};

/**
 * RECOMBEE | Načítání článků - nahrazování stávajících a s donačítáním dalších.
 * Používá se na HP.
 */
const dynamicRecombeeArticles = async () => {
    const recombeeWrappers = document.querySelectorAll('[data-recombee]');

    if (recombeeWrappers && recombeeWrappers.length) {
        const { renderArticles } = await import(
            /* webpackChunkName: 'chunk-recombee-articles' */
            './modules/renderArticles'
        );

        renderArticles(recombeeWrappers);
    }
};

/**
 * Anketa s přihlášením pro zobrazení výsledku.
 */
const dynamicEnquiry = async () => {
    const enquiryElements = document.querySelectorAll('.enquiry-bars');

    if (enquiryElements && enquiryElements.length) {
        const { enquiryBars } = await import(
            /* webpackChunkName: 'chunk-enquiry-bars' */
            '@default/scripts/modules/enquiryBars/enquiryBars'
        );

        enquiryBars(enquiryElements);
    }
};

/**
 *  Google One click Login Porta
 */
export const dynamicPortaInit = async () => {
    const userNotLogged = document.querySelector('[data-user-not-logged]'); // Kontrolujeme, jestli uzivatel uz neni prihlaseny.
    if (userNotLogged) {
        const { PortaInit } = await import(
            /* webpackChunkName: 'portaInit' */
            '@default/scripts/modules/porta/PortaInit'
        );

        PortaInit();
    }
};

/** Spusteni dynamickych importu */
const loadDynamicImports = () => {
    dynamicLoadMore();
    dynamicWriterList();
    dynamicElectionBannerList();
    dynamicElectionSearchList();
    dynamicIdvertCompensation();
    dynamicRecombeeArticlesInsert();
    dynamicRecombeeArticles();
    dynamicEnquiry();
    dynamicPortaInit();
};

loadDynamicImports();

// Úprava chování breaking news vzhledem k novému designu detailu článku
// mobileMenuWithBreakingNews();
